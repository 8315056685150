import React from "react";
import Layout from "../components/common/Layout";

function Privacypolicy() {
  return (
    <Layout>
      <section className="residence-banner relative">
        <div className="d-flex align-items-center abs-full tint">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="banner-title">Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="residence-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 policy-content text-justify">
              <p>
                We collect your personal information through our forms. Data
                requested but not required would be specified as optional.
              </p>
              <h5>What do we use your information for?</h5>
              <p>
                To personalize your experience. Your information helps us to
                better our response to match your requirements.
              </p>
              <p>
                To improve our website. We improve our website based on the
                information on who visits our website and what they are looking
                for.
              </p>
              <p>
                To improve customer service. Your feedback helps us improve our
                services.
              </p>
              <p>
                <b>Note:</b> If at any time you would like to unsubscribe from
                receiving future emails, we include detailed unsubscribe
                instructions at the bottom of each email.
              </p>
              <h5>How we protect your information?</h5>
              <p>
                Our directories and database are password protected to safeguard
                your personal information.
              </p>
              <h5>Do we use cookies?</h5>
              <p>
                Yes. Cookies are small files that a site or its service provider
                transfers to your computer hard drive through your Web browser
                (if you allow), that enables the sites or service providers
                systems to recognize your browser and capture and remember
                certain information.
              </p>
              <p>
                We use cookies to understand your preferences and save it to
                enhance your future visits. We also use them to compile data on
                site traffic and site interaction to enable us to offer better
                site experiences and tools in the future.
              </p>
              <p>
                We may also contract with third-party service providers to
                assist us in better understanding our site visitors. These
                service providers are not permitted to use the information
                collected on our behalf except to help us conduct and improve
                our business.
              </p>
              <h5>Do we disclose any information to outside parties?</h5>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your personally identifiable information. This does not include
                trusted third parties who assist us in operating our website,
                conducting our business, or servicing you, so long as those
                parties agree to keep this information confidential. We may also
                release your information when we believe the release is
                appropriate to comply with the law, enforce our site policies,
                or protect ours or others’ rights, property, or safety. However,
                non-personally identifiable visitor information may be provided
                to other parties for marketing, advertising, or other uses.
              </p>
              <h5>Third party links</h5>
              <p>
                Occasionally, at our discretion, we may include or offer third
                party products or services on our website. These third party
                sites have separate and independent privacy policies. We
                therefore have no responsibility or liability for the content
                and activities of these linked sites. Nonetheless, we seek to
                protect the integrity of our site and welcome any feedback about
                these sites.
              </p>
              <h5>Children’s Online Privacy Protection Act Compliance</h5>
              <p>
                We are in compliance with the requirements of COPPA (Children’s
                Online Privacy Protection Act). We do not collect any
                information from anyone under 13 years of age. Our website,
                products and services are all directed to people who are at
                least 13 years old or older.
              </p>
              <h5>CAN-SPAM Compliance</h5>
              <p>
                We have taken the necessary steps to ensure that we are
                compliant with the CAN-SPAM Act of 2003 by never sending out
                misleading information.
              </p>
              <h5>Online Privacy Policy Only</h5>
              <p>
                This online privacy policy applies only to information collected
                through our website and not to information collected offline.
              </p>
              <h5>Your Consent</h5>
              <p>By using our site, you consent to our Privacy Policy.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Privacypolicy;
